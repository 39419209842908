import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { appInitializer } from './app.initializer';

import { environment } from 'src/environments/environment'

import { MaterialModule } from './modules/shared/material/material.module';

import { PropiedadService } from './modules/shared/services/propiedad.service';

import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

const SERVER_URL = environment.SOCKET_IO;
const config: SocketIoConfig = { url: SERVER_URL, options: {} };

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializer,
      deps: [
        PropiedadService
      ]
    },
    PropiedadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
