import { PropiedadService } from "./modules/shared/services/propiedad.service";

export function appInitializer(
    propiedadService: PropiedadService
) {
    return () =>
        new Promise((resolve, reject) => {

            let url = window.location.href.split("?");
            let splitProperty = url[1].split("&prop=");
            let splitCodigo = splitProperty[0].split("cod=");
            
            let propertyId = splitProperty[1];
            let housekeeperCode = splitCodigo[1];

            propiedadService.propertyId = propertyId;
            propiedadService.housekeeperCode = housekeeperCode;

            resolve(true);

        });
}

