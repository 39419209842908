import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class PropiedadService {
    private currentPropertyId: string = '';
    private currentHousekeeperCode: string = '';

    constructor() { }

    set propertyId(value: string) {
        this.currentPropertyId = value;
    }

    get propertyId(): string {
        return this.currentPropertyId;
    }
    
    set housekeeperCode(value: string) {
        this.currentHousekeeperCode = value;
    }

    get housekeeperCode(): string {
        return this.currentHousekeeperCode;
    }
}